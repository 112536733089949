<template>
    <v-dialog v-model="notificationDialog" persistent max-width="600">
        <v-card>
            <v-card-title>Enable Notifications</v-card-title>
            <v-card-text>
                <Vue3Lottie :animationData="Bell" :height="200" :width="300" :speed="1" />
                It looks like you've disabled notifications. Would you like to enable them to stay updated?
            </v-card-text>
            <v-card-actions>
                <v-checkbox v-model="dontShowAgain" label="Don't show again"></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="requestNotificationPermission">Enable</v-btn>
                <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Bell from "@/assets/animjson/bell.json";
import { getToken, onMessage } from "firebase/messaging";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { messaging, firestore } from "@/firebase"; // Assuming these are set up

export default {
    name: "AllowNotification",
    data() {
        return {
            notificationDialog: false,
            dontShowAgain: false,
            Bell,
        };
    },
    methods: {
        checkNotificationPermission() {
            const permission = Notification.permission;
            const dontShowAgainState = localStorage.getItem("dontShowAgain") === "true";

            if (permission != "granted" && !dontShowAgainState) {
                this.notificationDialog = true;
            }

            console.log(permission);
        },
        requestNotificationPermission() {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    console.log("Notification permission granted.");
                    localStorage.setItem("dontShowAgain", "true");
                    this.retrieveFCMToken();
                } else {
                    console.log("Notification permission denied.");
                }
                this.notificationDialog = false;
                if (this.dontShowAgain) {
                    localStorage.setItem("dontShowAgain", "true");
                }
            });
        },
        retrieveFCMToken() {
            getToken(messaging, { vapidKey: 'BLRvshdbZJcwoxxt8iD4w0dchayn3JJAsHyK9llssSP6MeHupwhQ4jdIkeEzHMAkS4KG770jL2iqrMr3Y_nJGgE' })
                .then(async (currentToken) => {
                    if (currentToken) {
                        const userRef = doc(firestore, "users", localStorage.uid);
                        const userDoc = await getDoc(userRef);

                        let tokens = [];
                        if (userDoc.exists()) {
                            tokens = userDoc.data().token || [];
                            tokens = [...new Set(tokens)];
                            if (tokens.length >= 3) tokens.shift();
                            tokens.push(currentToken);
                            await updateDoc(userRef, { token: tokens });
                        } else {
                            await setDoc(userRef, { token: [currentToken] });
                        }
                    } else {
                        console.log("No registration token available.");
                    }
                })
                .catch((err) => {
                    console.log("An error occurred while retrieving token.", err);
                });
        },
        closeDialog() {
            this.notificationDialog = false;
            if (this.dontShowAgain) {
                localStorage.setItem("dontShowAgain", "true");
            }
        },
    },
    mounted() {
        this.checkNotificationPermission();
    },
};
</script>