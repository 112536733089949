import { createRouter, createWebHistory } from "vue-router";
import { getCurrentUser } from "@/firebase";

const Login = () => import("../views/login/login");
const Dashboard = () => import("../views/dashboard/dashboard");
const JobSearch = () => import("../views/jobs/search");
//
const MyAccount = () => import("../views/users/myAccount");
const Password = () => import("../views/login/password");
//
const OffsetCreate = () => import("../views/jobs/offset/create");
const OffsetWorkTicket = () => import("../views/jobs/offset/workticket");
//
const Artworks = () => import("../views/jobs/list");
const Items = () => import("../views/artwork/items");
const Versions = () => import("../views/artwork/versions");
const Revisions = () => import("../views/artwork/revisions");
const AnnotateArtwork = () => import("../views/artwork/annotate");
const ViewArtwork = () => import("../views/artwork/view");
const CompareArtworks = () => import("../views/artwork/compare");
//
const ReproList = () => import("../views/jobs/list");
const OSLList = () => import("../views/jobs/list");
const VPSList = () => import("../views/jobs/list");
const CTPList = () => import("../views/jobs/list");
const PlateoutList = () => import("../views/jobs/list");
const DispatchList = () => import("../views/jobs/list");
const CompletedList = () => import("../views/jobs/list");
//
const OffsetChecklistRepro = () => import("../views/jobs/checklist");
const OffsetChecklistOSL = () => import("../views/jobs/checklist");
const OffsetChecklistVPS = () => import("../views/jobs/checklist");
const OffsetChecklistCTP = () => import("../views/jobs/checklist");
const OffsetChecklistPlateout = () => import("../views/jobs/checklist");
const OffsetChecklistDispatch = () => import("../views/jobs/checklist");
const OffsetChecklistCompleted = () => import("../views/jobs/checklist");
//
const Flexo = () => import("../views/jobs/flexo/view");
const FlexoCreate = () => import("../views/jobs/flexo/create");
//
const Gatepass = () => import("../views/gatepass");
const GatepassVerify = () => import("../components/gatepass/verify");
//
const Plates = () => import("../views/inventory/plates");
const PlatesLog = () => import("../views/inventory/logs");
const Films = () => import("../views/inventory/films");
const ViewUser = () => import("../views/users/view");
const CreateUser = () => import("../views/users/createEdit");
const EditUser = () => import("../views/users/createEdit");
const Customers = () => import("../views/variables/customers");
const BulkUpload = () => import("../views/variables/bulkUpload");
const Agents = () => import("../views/variables/variables");
const Factories = () => import("../views/variables/variables");
const Locations = () => import("../views/variables/variables");
const Materials = () => import("../views/variables/variables");
const Machines = () => import("../views/variables/variables");
const Types = () => import("../views/variables/variables");
const PlateSizes = () => import("../views/variables/variables");
//
const Charges = () => import("../views/billing/artwork/charges");
const ChargesGenerated = () => import("../views/billing/artwork/generated");
const ChargesView = () => import("../views/billing/artwork/view");
const CreateSales = () => import("../views/billing/plates/create");
const ApproveSales = () => import("../views/billing/plates/approve");
const SentSales = () => import("../views/billing/plates/sent");
const UploadPurchase = () => import("../views/procurement/invoice/upload");
const VerifyPurchase = () => import("../views/procurement/invoice/verify");
const ApprovePurchase = () => import("../views/procurement/invoice/approve");
const SentPurchase = () => import("../views/procurement/invoice/sent");
const AutomationInvoice = () => import("../views/automation/invoice");
const AutomationReports = () => import("../views/automation/reports");
//
const PDF = () => import("../views/pdf");
const Scanner = () => import("../views/scanner/scan");
const SendEmail = () => import("../views/email/send");
const Outbox = () => import("../views/email/outbox");
const Logs = () => import("../views/logs");
const Page404 = () => import("../views/404");

// Define routes
const routes = [
  { path: "/login", name: "Login", component: Login, meta: { requiresGuest: true } },
  { path: "/gatepass/:id", name: "Gate Pass Verify", component: GatepassVerify, meta: { requiresGuest: true } },
  { path: "/", name: "Dashboard", component: Dashboard, meta: { requiresAuth: true } },
  { path: "/jobs/search/:searchTerm", name: "Search Results", component: JobSearch, meta: { requiresAuth: true } },
  //
  { path: "/myaccount", name: "My Account", component: MyAccount, meta: { requiresAuth: true } },
  { path: "/changepassword", name: "Change Password", component: Password, meta: { requiresAuth: true } },
  //
  { path: "/jobs/create", name: "Create Offset Jobs", component: OffsetCreate, meta: { requiresAuth: true } },
  { path: "/jobs/worktickets", name: "Work Tickets", component: OffsetWorkTicket, meta: { requiresAuth: true } },
  //
  { path: "/artworks", name: "Artworks", component: Artworks, meta: { requiresAuth: true } },
  { path: "/artworks/:jobID", name: "Artwork Details", component: Items, meta: { requiresAuth: true } },
  { path: "/artworks/:jobID/:itemID", name: "Item Details", component: Versions, meta: { requiresAuth: true } },
  { path: "/artworks/:jobID/:itemID/:versionID", name: "Version Details", component: Revisions, meta: { requiresAuth: true } },
  { path: "/artworks/:jobID/:itemID/:versionID/annotate/:revisionID", name: "Annotate Artwork", component: AnnotateArtwork, meta: { requiresAuth: true } },
  { path: "/artworks/:jobID/:itemID/:versionID/view/:revisionID", name: "View Artwork", component: ViewArtwork, meta: { requiresAuth: true } },
  { path: "/artworks/:jobID/:itemID/:versionID/compare/:revisionID1/:revisionID2", name: "Compare Artworks", component: CompareArtworks, meta: { requiresAuth: true } },
  //
  { path: "/jobs/repro", name: "Repro", component: ReproList, meta: { requiresAuth: true } },
  { path: "/jobs/osl", name: "Outsourced Items", component: OSLList, meta: { requiresAuth: true } },
  { path: "/jobs/vps", name: "Imposition/VPS", component: VPSList, meta: { requiresAuth: true } },
  { path: "/jobs/ctp", name: "CtP", component: CTPList, meta: { requiresAuth: true } },
  { path: "/jobs/plateout", name: "Plateout", component: PlateoutList, meta: { requiresAuth: true } },
  { path: "/jobs/dispatch", name: "Dispatch", component: DispatchList, meta: { requiresAuth: true } },
  { path: "/jobs/completed", name: "Completed", component: CompletedList, meta: { requiresAuth: true } },
  //
  { path: "/jobs/repro/checklist/:jobID", name: "Repro Checklist", component: OffsetChecklistRepro, meta: { requiresAuth: true } },
  { path: "/jobs/osl/checklist/:jobID", name: "Outsourced items Checklist", component: OffsetChecklistOSL, meta: { requiresAuth: true } },
  { path: "/jobs/vps/checklist/:jobID", name: "Imposition/VPS Checklist", component: OffsetChecklistVPS, meta: { requiresAuth: true } },
  { path: "/jobs/ctp/checklist/:jobID", name: "CtP Checklist", component: OffsetChecklistCTP, meta: { requiresAuth: true } },
  { path: "/jobs/plateout/checklist/:jobID", name: "Plateout Checklist", component: OffsetChecklistPlateout, meta: { requiresAuth: true } },
  { path: "/jobs/dispatch/checklist/:jobID", name: "Dispatch Checklist", component: OffsetChecklistDispatch, meta: { requiresAuth: true } },
  { path: "/jobs/completed/checklist/:jobID", name: "Completed Summary", component: OffsetChecklistCompleted, meta: { requiresAuth: true } },
  //
  { path: "/flexo", name: "Flexo Jobs", component: Flexo, meta: { requiresAuth: true } },
  { path: "/jobs/create/flexo", name: "Create Flexo Job", component: FlexoCreate, meta: { requiresAuth: true } },
  //
  { path: "/gatepass", name: "Gate Pass", component: Gatepass, meta: { requiresAuth: true } },
  //
  { path: "/inventory/plates", name: "Plates Inventory", component: Plates, meta: { requiresAuth: true } },
  { path: "/inventory/plates/log/:location/:material", name: "Plates Inventory Log", component: PlatesLog, meta: { requiresAuth: true } },
  { path: "/inventory/films", name: "Films Inventory", component: Films, meta: { requiresAuth: true } },
  { path: "/users", name: "Users", component: ViewUser, meta: { requiresAuth: true } },
  { path: "/users/create", name: "Create User", component: CreateUser, meta: { requiresAuth: true } },
  { path: "/users/edit/:userid", name: "Edit User", component: EditUser, meta: { requiresAuth: true } },
  { path: "/customers", name: "Customers", component: Customers, meta: { requiresAuth: true } },
  { path: "/bulkupload", name: "Customers Bulk upload", component: BulkUpload, meta: { requiresAuth: true } },
  { path: "/agents", name: "Agents", component: Agents, meta: { requiresAuth: true } },
  { path: "/factories", name: "Factories / Plants", component: Factories, meta: { requiresAuth: true } },
  { path: "/locations", name: "CtP Locations", component: Locations, meta: { requiresAuth: true } },
  { path: "/materials", name: "Materials", component: Materials, meta: { requiresAuth: true } },
  { path: "/machines", name: "Print Machines", component: Machines, meta: { requiresAuth: true } },
  { path: "/types", name: "Job Types", component: Types, meta: { requiresAuth: true } },
  { path: "/plateSizes", name: "Plate Sizes", component: PlateSizes, meta: { requiresAuth: true } },
  //
  { path: "/billing/artwork/charges", name: "Prepress Charges", component: Charges, meta: { requiresAuth: true } },
  { path: "/billing/artwork/generated", name: "Generated Reports", component: ChargesGenerated, meta: { requiresAuth: true } },
  { path: "/billing/artwork/generated/:reportID", name: "View Reports", component: ChargesView, meta: { requiresAuth: true } },
  { path: "/billing/plates/create", name: "Create Plate Invoices", component: CreateSales, meta: { requiresAuth: true } },
  { path: "/billing/plates/approve", name: "Approve Plate Invoices", component: ApproveSales, meta: { requiresAuth: true } },
  { path: "/billing/plates/sent", name: "Sent Plate Invoices", component: SentSales, meta: { requiresAuth: true } },
  { path: "/procurement/invoice/upload", name: "Upload Invoices", component: UploadPurchase, meta: { requiresAuth: true } },
  { path: "/procurement/invoice/verify", name: "Verify Invoices", component: VerifyPurchase, meta: { requiresAuth: true } },
  { path: "/procurement/invoice/approve", name: "Approve Invoices", component: ApprovePurchase, meta: { requiresAuth: true } },
  { path: "/procurement/invoice/sent", name: "Approved Invoices", component: SentPurchase, meta: { requiresAuth: true } },
  { path: "/automation/invoice", name: "Invoice", component: AutomationInvoice, meta: { requiresAuth: true } },
  { path: "/automation/reports", name: "Reports", component: AutomationReports, meta: { requiresAuth: true } },
  //
  { path: "/pdf", name: "PDF", component: PDF, meta: { requiresAuth: true } },
  { path: "/scanner", name: "Scanner", component: Scanner, meta: { requiresAuth: true } },
  { path: "/email/send", name: "Compose Email", component: SendEmail, meta: { requiresAuth: true } },
  { path: "/outbox", name: "Outbox", component: Outbox, meta: { requiresAuth: true } },
  { path: "/logs", name: "Logs", component: Logs, meta: { requiresAuth: true } },
  { path: "/:pathMatch(.*)", name: "Page not found", component: Page404, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Simplified Nav Guard
router.beforeEach(async (to, from, next) => {
  const currUser = await getCurrentUser();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresGuest = to.matched.some((record) => record.meta.requiresGuest);

  if (requiresAuth && !currUser) {
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else if (requiresGuest && currUser) {
    next({ path: "/", query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
