<template>
    <v-list-item :prepend-icon="prependIcon" :title="title" link :color="color" rounded="shaped" :to="to"
        density="compact" slim>
        <template v-slot:append>
            <v-badge v-if="showBadge && badgeValue >= 1" :color="color" :content="badgeValue" inline></v-badge>
            <v-progress-circular v-if="showBadge && badgeValue === null" class="mr-2" :color="color" indeterminate
                :size="20" :width="2"></v-progress-circular>
        </template>
    </v-list-item>
</template>

<script>
export default {
    props: {
        prependIcon: String,
        title: String,
        color: String,
        to: String,
        badgeValue: {
            type: [String, Number, null],
            default: null
        },
        showBadge: {
            type: Boolean,
            default: false
        }
    }
}
</script>